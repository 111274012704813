* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins, sans-serif !important;

}

input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}
input[type=time]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=time]:focus::-webkit-datetime-edit {
    color: black !important;
}

input::-webkit-input-placeholder{
    color:#49454F;
}
input:-moz-placeholder {
    color:#49454F;
}