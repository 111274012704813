.bottom-nav {
    width: 100%;
    height: 75px;
    background-color: white;
    box-shadow: 12px 10px 5px 12px whitesmoke;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
